<template>
  <secured-area
    :operations="['View_Role', 'Create_Role', 'Edit_Role', 'Add_User_To_Role', 'Remove_User_From_Role', 'View_Users_In_Roles', 'Grant_Operation_To_Role', 'Revoke_Operation_From_Role', 'View_Operations_For_Roles']"
    :hideWhenAccessIsRejected="true"
  >
    <b-form @reset="onReset">
      <b-form-row>
        <b-col>
          <form-field
            :compactMode="compactMode"
            caption="Nom technique du rôle"
            :invalidFeedback="invalidNameMessage"
            :state="isNameValid">
            <template #info-helper-placeholder>
                <info-helper>
                  <div><b>Nom technique</b> = Le code interne permettant d'associer des droits aux utilisateurs.</div>
                  <!--<div>Représente le nom avec lequel l'utilisateur va pouvoir se connecter via l'écran de connexion lui demandant la saisie de son <b>Login</b> et <b>Mot de passe</b></div>-->
                </info-helper>
            </template>
            <b-form-input type="text" :readonly="isReadonly('name')"  v-model="name" required :state="isNameValid"/>
          </form-field>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-field :compactMode="compactMode" caption="Nom public (d'affichage)"
            :invalidFeedback="invalidDisplayNameMessage"
            :state="isDisplayNameValid"
          >
            <b-form-input type="text" :readonly="isReadonly('displayName')" v-model="displayName" :state="isDisplayNameValid" />
          </form-field>
        </b-col>
      </b-form-row>

      <b-form-row>
        <b-col>
          <form-field :compactMode="compactMode" :required="true" caption="Description"
            :invalidFeedback="invalidDescriptionMessage"
            :state="isDescriptionValid"
          >
            <b-textarea :readonly="isReadonly('description')" v-model="description" :state="isDescriptionValid" />
          </form-field>
        </b-col>
      </b-form-row>

      <form-bottom-action-bar>
         <template #right>
          <b-button type="reset" v-show="userCan('Edit_Role')" :disabled="!hasChanged">Annuler</b-button>
          <b-button type="submit" v-show="userCan('Edit_Role')" :disabled="!hasChanged">Enregistrer</b-button>
        </template>
      </form-bottom-action-bar>
    </b-form>
  </secured-area>
</template>

<script>
import { mapActions } from 'vuex';
import InfoHelper from '@/components/InfoHelper.vue';
import SecuredArea from '@/components/Security/SecuredArea.vue';
import FormField from '@/components/Panels/FormField.vue';
import FormBottomActionBar from '@/components/FormBottomActionBar';


export default{
  components: { InfoHelper, SecuredArea, FormField, FormBottomActionBar },
  props: { editedRole: { type:Object, default:null }, compactMode: { type:Boolean, default:false}},
  model: {
    prop: 'editedRole',
  },
  data(){
    return {
      userCanEdit: false,
      userCanView: false,
 /*     userCanAddUser: false,
      userCanRemoveUser: false,*/
      //lastRightsCheck: null,

      name: '',
      displayName: '',
      description: '',
      isActive: false,

      nameMaxLength: 100,
      displayNameMaxLength: 100,
      descriptionMaxLength: 4096,

      hasChanged: false,
    }
  },
  computed:{
    isNameValid(){
      return !(this.name.trim().length > 0 && this.name.trim().length <= this.nameMaxLength) ? false : null;
    },
    invalidNameMessage(){
      var trimmed = this.name.trim();
      if(trimmed.length == 0)
        return "Le Nom (technique) du rôle est requis !";
      if (trimmed.length > this.nameMaxLength)
        return `Le Nom (technique) du rôle ne peut pas excéder ${this.nameMaxLength} caractères.`;
      return "Ce nom (technique) n'est pas valide.";
    },
    isDisplayNameValid(){
      return !(this.displayName.trim().length <= this.displayNameMaxLength) ? false : null;
    },
    invalidDisplayNameMessage(){
      var trimmed = this.displayName.trim();
      if (trimmed.length > this.displayNameMaxLength)
        return `Le nom (public) du rôle ne peut pas excéder ${this.displayNameMaxLength} caractères.`;
      return "Ce nom (public) n'est pas valide.";
    },
    isDescriptionValid(){
      return !(this.description.trim().length <= this.descriptionMaxLength) ? false: null;
    },
    invalidDescriptionMessage(){
      var trimmed = this.description.trim();
      if (trimmed.length > this.descriptionMaxLength)
        return `La description du rôle ne peut pas excéder ${this.descriptionMaxLength} caractères.`;
      return "Cette description n'est pas valide.";
    },
  },
  methods: {
    ...mapActions('auth', ['userCan']),
    async refreshRights(){
      this.userCanVview = await this.userCan('View_Role');
      this.userCanEdit = await this.userCan('Edit_Role');
      this.userCanCreate = await this.userCan("Create_Role");
      /*this.userCanAddUser = await this.userCan("Add_User_To_Role");
      this.userCanRemoveUser = await this.userCan('Remove_User_From_Role');
      this.userCanGrantOperation = await this.userCan('Grant_Operation_To_Role');
      this.userCanRevokeOperation = await this.userCan('Revoke_Operation_From_Role');*/
      return true;
    },
    isReadonly(){
      return (this.editedRole == null)
        ? !this.userCanCreate
        : !this.userCanEdit;
    },
    initBuffer(value){
      this.name = (value == null) ? '' : value.name;
      this.displayName = (value == null) ? '' : (value.displayName == null ? '' : value.displayName);
      this.description = (value == null) ? '' : (value.description == null ? '' : value.description);
      this.isActive = (value == null) ? false : value.isActive;
    },
    onSubmit(e){
      console.log('onSubmit', e);
      this.$emit('submit', this.roleBuffer);
    },
    onReset(e){
      e.preventDefault();
      this.initBuffer(this.editedRole);
      console.log('onReset', e);
      this.$emit('reset');
      this.hasChanged = false;
    }
  },
  watch: {
    editedRole: function(newVal, oldVal) { // watch it
      console.log('role changed: ', newVal, ' | was: ', oldVal);
      this.initBuffer(newVal);
    }
  },
  async mounted(){
    await this.refreshRights();
    this.initBuffer(this.editedRole);
  }
}
</script>

<style scoped>
  div.input-group { margin-top: 5px; margin-bottom:5px; margin-left: 15px; width: calc(100% - 30px); }
  div.input-group.required .input-group-text::after { content:'* '; color:var(--ffvlAlert);}
</style>
