<template>
<secured-area :operations="['Grant_Operation_To_Role', 'Revoe_Operation_From_Role', 'View_Operations_For_Roles']">
  <b-checkbox switch v-model="showDisplayName" button-variant="info" > &gt; <i>{{ showDisplayName ? 'Noms techniques' : 'Noms publics'}}</i></b-checkbox>
  <b-form @reset="onReset" @submit="onSubmit">
    <ul class="flex">
      <li v-for="operation in operations" :key="operation.name" class="">
        <b-checkbox size="sm" :checked="isSet(operation)" :class="{isSet:isSet(operation)}" :disabled="!canChange(operation)" @change="toggle(operation)" :id="'ope_' + operation.name ">
          <label :for="'ope_' + operation.name"> {{ showDisplayName ?  operation.displayName : operation.name }}</label>
          <info-helper v-if="operation.description && !operation.description == ''">
            {{ operation.description  }}
          </info-helper>
        </b-checkbox>
      </li>
    </ul>
    <form-bottom-action-bar>
      <template #right>
        <b-button type="reset" v-show="userCan('Grant_Operation_To_Role') || userCan('Revoke_Operation_From_Role')" :disabled="!hasChanged" >Annuler</b-button>
        <b-button type="submit" v-show="userCan('Grant_Operation_To_Role') || userCan('Revoke_Operetion_From_Role')" :disabled="!hasChanged">Enregistrer</b-button>
      </template>
    </form-bottom-action-bar>
  </b-form>
</secured-area>
</template>

<script>
import axios from "axios";
import SecuredArea from '@/components/Security/SecuredArea.vue';
import FormBottomActionBar from '@/components/FormBottomActionBar';
import { mapActions } from 'vuex';
import InfoHelper from '../../InfoHelper.vue';
//import FormActionButtonsArea from '@/components/FormActionButtonsArea.vue';

export default {
  components: { SecuredArea, FormBottomActionBar, InfoHelper/*, FormActionButtonsArea*/ },
  model: {
    prop: 'editedOperations',
  },
  props: {
    editedOperations: { type: Array, required: true} ,
    showDisplayName: { type:Boolean, default:true },
  },
  data(){
    return {
      operations: [],
      buffer: [],
      hasChanged:false,
    }
  },
  methods:{
    ...mapActions('auth', ['userCan']),
    isSet(operation)
    {
      if(this.buffer && this.buffer.length > 0){
        return this.buffer.findIndex(or => or.name.trim().toLowerCase() == operation.name.trim().toLowerCase()) >= 0;
      }
      return false;
    },
    refresh(){
      var url = this.getUrl('/api/Security/getOperations.php');

      axios.get(url)
        .then(response=>{
          this.operations = response.data.operations;
        })
        .catch(error => {
          console.log('Operations refresh error', error);
        });
    },
    canChange(operation)
    {
      if(this.isSet(operation)){
        return this.userCan('Revoke_Operation_From_Role');
      }else{
        return this.userCan('Grant_Operation_To_Role');
      }
    },
    toggle(operation)
    {
      //('toggle', operation);
      if(this.isSet(operation)){
        this.buffer = this.buffer.filter(o => o.name.trim().toLowerCase() != operation.name.trim().toLowerCase());
      }else{
        this.buffer.push(operation);
      }
      this.hasChanged = this.checkHasChanged();
    },
    getFinalState(){
      var state = this.editedOperations;
      return state;
    },
    checkHasChanged(){
      if(this.buffer.length != this.editedOperations.length) {
        return true;
      }
      const initState = this.editedOperations.map(o => o.name).sort((a,b) => a.localeCompare(b, undefined, 'base'));
      const newState = this.buffer.map(o => o.name).sort((a,b) => a.localeCompare(b, undefined, 'base'));
      return newState.join() != initState.join();
    },
    onSubmit(e){
      e.preventDefault();
      //this.console.log('onSubmit', e);
      this.$emit('submit', this.clone(this.buffer));
    },
    onReset(e){
      e.preventDefault();
      this.buffer = this.clone(this.editedOperations);
      //this.console.log('onReset', e);
      this.$emit('reset');
      this.hasChanged = this.checkHasChanged();
      this.toastReset("Les changements d'opération en cours pour le rôle ont été annulés.");
    },
    clone(source){
      return JSON.parse(JSON.stringify(source));
    }
  },
  mounted(){
    this.buffer = (this.editedOperations == null) ? [] : this.clone(this.editedOperations);
    this.refresh();
  },
    watch: {
    editedOperations: function(newVal, oldVal) { // watch it
      console.log('operations changed: ', newVal, ' | was: ', oldVal);
      this.buffer = (newVal == null) ? [] : this.clone(newVal);
      this.refresh();
      this.hasChanged = this.checkHasChanged();
    }
  },
}
</script>

<style scoped>
  ul { display: -ms-flex;
    display: -webkit-flex;
    display: flex;
    flex-direction: row; flex-wrap: wrap; }
  li { list-style-type: none; width: 50%; padding:3px; }
  .info-text { margin-left: 15px; }
  /*li:first-child{ margin-right: 20px;}*/
  .isSet { color:saddlebrown; font-weight: bold; }
</style>

